import { FC, useEffect } from "react";
import { Form, Space } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { additionalLanguages, deliveryChannel } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGeneral, PageItemLayoutGroup, TooltipInfo } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

export interface SmsGeneralSettingsForm {
  "enable-sms-glob": string;
  "nic-sms-t-deliv-ch": string;
  "default-lang": string;
  "nic-sms-t-lang1": string;
  "nic-sms-t-lang2": string;
}

export const SmsGeneralSettings: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    if (!isLoading) {
      form.setFieldsValue({
        "enable-sms-glob": !(
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "enable-sms-glob")?.value === "false"
        ),
        "default-lang": "English",
        "nic-sms-t-deliv-ch":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-sms-t-deliv-ch")?.value || "TIBCO",
        "nic-sms-t-lang1": tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-sms-t-lang1")?.value,
        "nic-sms-t-lang2": tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-sms-t-lang2")?.value,
      });
    }
  }, [form, isLoading, tenant]);

  const onFinish = (value: SmsGeneralSettingsForm) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(value).map((val: string) => {
        if (val === "enable-sms-glob") {
          return {
            fieldCode: val,
            value: value[val as keyof SmsGeneralSettingsForm] ? "true" : "false",
          };
        }

        return {
          fieldCode: val,
          value: value[val as keyof SmsGeneralSettingsForm] || "",
        };
      }),
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setTenant(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="SMS Templates"
      pageSubtitle={
        <Space direction="vertical" size={16}>
          <div className={styles["section-description"]}>
            <TooltipInfo
              largeLabel="For product setup, the platform allows SMS, or Short Text Message, to be sent out to cardholder’s registered phone number (a part of demographic information setup at Account on-boarding) when triggered by a certain event."
              tooltipProps={{
                className: "warning-sign",
                title: (
                  <>
                    <span>For example:</span>
                    <ul>
                      <li>Card Activation SMS – Triggered when card is activated.</li>
                      <li>PIN Change Notification – Triggered when PIN is set or reset.</li>
                      <li>Advance Notification of Card Expiry based on Advance Renewal Period.</li>
                      <li>Account/Card cancellation SMS</li>
                    </ul>
                  </>
                ),
              }}
            />
          </div>
          <div className={styles["section-description"]}>
            The message for each notification can be defined for the customer Account or Card. Predefined templates are
            available as a standard product setup. You have an option to either use your own SMS gateway or Network
            International’s SMS services as an optional value added service.
          </div>
        </Space>
      }
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="full"
      size="lg"
      submitLabel="Save"
    >
      <PageItemLayoutGeneral>
        <PageItemLayoutGroup className={styles["sms-general-settings-container"]}>
          <div className={styles["sms-general-settings-block"]}>
            <div className={styles["sms-general-settings-block-header"]}>
              <NetworkForm.Switch
                formItemOptions={{
                  valuePropName: "checked",
                  name: "enable-sms-glob",
                  label: "Enable SMS functionality",
                }}
              />
            </div>
            <div className={styles["sms-general-settings-block-body"]}>
              <NetworkForm.Select
                disabled={true}
                formItemOptions={{
                  label: "Default language",
                  name: "default-lang",
                  className: styles["sms-general-settings-select-item"],
                }}
                optionList={[{ label: "English", value: "English" }]}
              />

              <div className={styles["sms-general-settings-select-item"]}>
                <div className={cn(styles["coming-soon"], "alert-div")}>Coming soon</div>
              </div>

              <NetworkForm.Select
                formItemOptions={{
                  className: styles["sms-general-settings-select-item"],
                  name: "nic-sms-t-lang1",
                  label: "Additional language 1",
                }}
                optionList={additionalLanguages}
                disabled={true}
              />

              <NetworkForm.Select
                formItemOptions={{
                  className: styles["sms-general-settings-select-item"],
                  name: "nic-sms-t-lang2",
                  label: "Additional language 2",
                }}
                optionList={additionalLanguages}
                disabled={true}
              />
            </div>
          </div>
        </PageItemLayoutGroup>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
